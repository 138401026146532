
/***
 * GERAL
 ***/
$(function() {
    $('.animsition').animsition().one('animsition.inStart',function(){
        App.SetToggle();
        App.SetGaleria();
        App.SetBanner();
        App.SetCarouselMobile();
        App.SetHeaderFixed(150);
        App.SetValidate();
        App.SetMaskInput();
        App.SetInputError();
        App.SetFormSuccess();
        App.SetAnimatescroll(150);
        App.SetWow(200);

        $('html').addClass('loaded');

        // Cookies
        if (localStorage.hasOwnProperty('verify_cookies')) {
            $('.Cookies').addClass('_hide');
        }

        $('.Cookies .Button').click(function(event) {
            event.preventDefault();
            localStorage.setItem('verify_cookies', true);
            $('.Cookies').addClass('_hide');
        });

        // Input file
        $(document).on('change', '#assunto', function(event) {
            if ($(this).val() == 'Enviar curriculo') {
                $('._curriculo-input').removeClass('_hide');
            } else {
                $('._curriculo-input').addClass('_hide');
            }
        });

        $(document).on('change', '.inputfile', function(event) {
            event.preventDefault();

            var fullPath = document.getElementById('curriculo').value;
            if (fullPath) {
                var startIndex = (fullPath.indexOf('\\') >= 0 ? fullPath.lastIndexOf('\\') : fullPath.lastIndexOf('/'));
                var filename = fullPath.substring(startIndex);
                if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
                    filename = filename.substring(1);
                }
            }

            $(this).next().text(filename);
        });
    });
});


